<template>
  <Dropdown
    v-model="currentStatus"
    v-if="activate || activate === undefined"
    :options="statuses"
    optionValue="value"
    optionLabel="name">
    <template #value="slotProps">
      <div class="status" style="font-size: 14px" v-if="slotProps.value">
        <span :class="`text-${ statuses.find(e => e.value === slotProps.value)?.class }`">&#9679;</span>
        {{ statuses.find(e => e.value === slotProps.value)?.name }}
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
    <template #option="slotProps">
      <div class="status-option" style="font-size: 14px">
        <span :class="`text-${slotProps.option.class}`">&#9679;</span> {{slotProps.option.name}}
      </div>
    </template>

  </Dropdown>
  <div
    v-else
    style="font-size: 14px; font-weight: 400">
    <span :class="`text-${ statuses.find(e => e.value === currentStatus)?.class }`">&#9679;</span>
    {{ statuses.find(e => e.value === currentStatus)?.name }}
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, watch, reactive } from 'vue'

export default ({
  name: 'UStatusDropdown',
  props: {
    onUpdateStatus: {
      type: Function,
      required: false,
    },
    currentStatus: {
      type: String,
      required: true,
    },
    activate: {
      required: false,
    }
  },
  setup(props) {
    const {t} = useI18n();
    const currentStatus = ref(props.currentStatus);
    const activate = ref(props.activate);
    const statuses = [
      { name: 'To do', value: 'to_do', class: 'toDo' },
      { name: 'In progress', value: 'in_progress', class: 'inProgress'},
      { name: 'Completed', value: 'completed', class: 'completed' }
    ]

    watch(() => currentStatus.value, (newVal, oldVal) => {
      props.onUpdateStatus && props.onUpdateStatus(newVal);
    });

    return {
      currentStatus,
      statuses,
      activate,
      t,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>


