<template>
  <div class="flex overflow-hidden">
    <Sidebar/>

    <CreateOrder
      v-if="isAdmin"
      :show="getShowCreateOrder()"
      :toggleShow="toggleCreateOrder"
      :toggleReloadOrders="toggleReloadOrders"/>

    <div class="flex flex-col p-3 w-4/5 h-screen overflow-y-auto overflow-x-hidden">
      <div class="flex justify-between items-center text-4xl font-bold mt-6 ml-4 mb-14">
        <template v-if="isAdmin">{{ t('orders.all_orders') }}</template>
        <template v-if="isCustomer">{{ t('orders.my_orders') }}</template>
        <button
          v-if="isAdmin"
          class="bg-primary text-black w-52 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
          @click="toggleCreateOrder">
          <svg class="w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          {{ t('orders.create_new_order') }}
        </button>
        <a :href="getARAGUrl()"
          v-if="isARAGUser() && !isAdmin"
          class="bg-primary text-black w-52 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
          @click="toggleCreateOrder">
          <svg class="w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          {{ t('orders.create_new_order') }}
        </a>
        <a :href="webshopUrl"
          v-if="!isARAGUser() && !isAdmin"
          class="bg-primary text-black w-52 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg"
          @click="toggleCreateOrder">
          <svg class="w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
          </svg>
          {{ t('orders.create_new_order') }}
        </a>
      </div>

      <FilterOrder
        v-if="isAdmin"
        :onFilterOrders="onFilterOrders"
      />
      <div
        v-if="!loaded"
        class="flex flex-col m-4 flex-grow relative">
        <LoaderSpinner isActive="true"/>
      </div>
      <OrdersList
        v-if="isAdmin"
        v-show="loaded"
        :orders="getOrders()"
        :updateOrderStatus="updateOrderStatus" />
      <OrdersListCustomer
        v-else
        v-show="loaded"
        :orders="getOrders()"
        :updateOrderStatus="updateOrderStatus" />

      <div class="p-4 flex justify-center" :class="loaded ? '' : 'opacity-40 pointer-events-none'">
        <v-pagination
          v-model="currentPage"
          :pages="maxPages"
          :range-size="1"
          active-color="rgb(159, 206, 77)"
          @update:modelValue="pageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Sidebar from '@/components/common/Sidebar.vue';
import LoaderSpinner from '@/components/common/LoaderSpinner.vue';
import FilterOrder from '@/components/FilterOrder.vue';
import OrdersList from '@/components/OrdersList.vue';
import OrdersListCustomer from '@/components/OrdersListCustomer.vue';
import CreateOrder from '@/components/CreateOrder.vue';
import httpClient from '@/services/httpClient';

export default {
  name: 'Orders',

  components: {
    Sidebar,
    FilterOrder,
    OrdersList,
    OrdersListCustomer,
    CreateOrder,
    LoaderSpinner,
    VPagination,
  },

  setup() {
    const {t} = useI18n();
    const orders = ref([]);
    const maxPages = ref(1);
    const currentPage = ref(1);
    const showCreateOrder = ref(false);
    const loaded = ref(false);
    const store = useStore();
    const isAdmin = computed(() => store.getters['AuthModule/isAdmin']);
    const isCustomer = computed(() => store.getters['AuthModule/isCustomer']);
    const userDetails = computed(() => store.getters['AuthModule/userDetails']);

    let activeFilters = {
      services: {},
      orderStatus: {},
      paymentStatus: {},
      date: {},
    };

    function isARAGUser() {
      return userDetails.value.userSource == "ARAG" || userDetails.value.userSource == "ARAG Schaden";
    }

    function getARAGUrl(){
      if(userDetails.value.userSource == "ARAG"){
        return process.env.VUE_APP_ARAG_URL;
      }else{
        return process.env.VUE_APP_ARAG_DAMAGE_URL;
      }
    }

    const webshopUrl = computed(() => {
      return httpClient.website('/shop');
    })

    const fetchOrders = async (page, filters) => {
      loaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/orders',
        {
          page: page || 1,
          ...filters,
        }
      );
      orders.value = result.data.pages;
      maxPages.value = result.data.max_pages;
      loaded.value = true;
    }

    onMounted(async () => {
      await fetchOrders();
    })

    function getOrders() {
      return orders;
    }

    function getShowCreateOrder() {
      return showCreateOrder.value;
    }

    function toggleCreateOrder() {
      showCreateOrder.value = !showCreateOrder.value;
    }

    async function toggleReloadOrders() {
      await fetchOrders();
    }

    async function onFilterOrders(filter) {
      activeFilters = { ...activeFilters, ...filter };
      currentPage.value = 1;
      await fetchOrders(1, activeFilters);
    }

    async function updateOrderStatus(order) {
      const response = await httpClient.post(
        '/api/v1/dgr/order_update', {
          order_id: order.orderId,
          status: order.status,
        });
    }

    async function pageChange(e) {
      await fetchOrders(e, {... activeFilters });
    }

    return {
      t,
      orders,
      showCreateOrder,
      getShowCreateOrder,
      toggleCreateOrder,
      toggleReloadOrders,
      getOrders,
      updateOrderStatus,
      currentPage,
      pageChange,
      maxPages,
      loaded,
      onFilterOrders,
      isAdmin,
      isCustomer,
      isARAGUser,
      getARAGUrl,
      webshopUrl,
    }
  }
}
</script>

