<template>
  <div class="flex flex-col m-4 flex-grow">
    <div class="my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
      <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr class=" h-12 py-2  text-gray-600 text-xs rounded-lg">
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('orders_list_customer.view_order') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('orders_list_customer.order_nr') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('orders_list_customer.order_date') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('orders_list_customer.payment_status') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('orders_list_customer.payment_type') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('orders_list_customer.order_status') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="order in orders">
              <tr class="h-16 py-2 text-sm hover:bg-gray-100 clickable-row" @click="goToSingleOrder(order.orderId)">
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  <button
                      class="bg-primary text-black w-40 rounded-3xl py-3 text-xs flex justify-center items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg">
                      {{ t('orders_list_customer.view_order') }}
                    </button>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  {{ order.orderId }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  {{ order.orderDate }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  <div class="flex items-center">
                    <span class="mr-1" v-html="paymentDescription(order).icon"></span>
                    {{ paymentDescription(order).description }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-xs">
                  {{ order.paymentType }}
                </td>
                <td class="py-1 whitespace-nowrap relative text-xs">
                  <UStatusDropdown
                    :activate="false"
                    :currentStatus="order.status"
                  />
                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {useI18n} from 'vue-i18n';
import UStatusDropdown from './UStatusDropdown.vue'
import { useRouter } from 'vue-router'

export default ({
  name: 'OrdersList',
  components: {
    UStatusDropdown
  },
  props: {
    orders: Object,
  },
  setup(props) {
    const orders = props.orders;
    const {t} = useI18n();
    const router = useRouter()

    function paymentDescription(order) {
      const checkIcon = `<svg class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>`;
      const pendingIcon = `<svg class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>`;
      const timesIcon = `<svg class="w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>`;

      const description = {
        icon: '',
        description: order.paymentStatus,
      }

      switch (order.paymentStatus) {
        case 'completed':
        case 'refunded':
          description.icon = checkIcon;
          break;
        case 'pending':
        case 'processing':
        case 'on-hold':
          description.icon = pendingIcon;
          break;
        case 'cancelled':
        case 'failed':
          description.icon = timesIcon;
          break;
      }

      return description;
    }
    const goToSingleOrder = (id) => {
      router.push({ name: 'SingleOrder' , params: { id }})
    }

    return {
      t,
      paymentDescription,
      orders,
      goToSingleOrder
    };
  },
});

</script>

<style lang="scss" scoped>
.clickable-row {
  cursor: pointer;
}
</style>


