<template>
  <div class="flex flex-col justify-start items-start px-4">
    <SearchBar :onFilterOrders="onFilterOrders"/>
    <DropdownFilters :onFilterOrders="onFilterOrders"/>
  </div>

</template>

<script>
import SearchBar from './SearchBar.vue';
import DropdownFilters from './DropdownFilters.vue';

export default ({
  name: 'FilterOrder',
  props: {
    onFilterOrders: Function,
  },
  components: {
    SearchBar,
    DropdownFilters
  },
  setup(props) {
    const onFilterOrders = props.onFilterOrders;

    return {
      onFilterOrders,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
