<template>
  <div class="w-1/5">
    <div class="mt-1">

      <button type="button"
              class="bg-white border w-24 border-gray-300 rounded-md shadow-sm pl-1 py-1 text-left cursor-pointer focus:outline-none text-xs z-10"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              @click="toggleDropdown">
        <span class="flex items-center">
          <span :class="getStatusClass(currentStatus)">&#9679;</span>
          <span class=" block truncate">{{ getLabel(currentStatus) }}</span>
        </span>
      </button>

      <ul :class="{'absolute -top-3 bg-white shadow-lg w-24 max-h-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none z-20' : showDropdown, 'hidden' : !showDropdown}"
          tabindex="-1" role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-0">

        <li class="text-gray-900 cursor-pointer select-none relative py-1 px-1 text-xs hover:bg-gray-100"
            id="listbox-option-0"
            role="option"
            @click="setCurrentStatus('to_do')">
          <div class="flex items-center">
            <span class="text-toDo">&#9679;</span>
            <span class="font-normal ml-2 block truncate">
              {{ t('order_status.to_do') }}
            </span>
          </div>
        </li>

        <li class="text-gray-900 cursor-pointer select-none relative py-1 px-1 text-xs hover:bg-gray-100"
            id="listbox-option-1"
            role="option"
            @click="setCurrentStatus('in_progress')">
          <div class="flex items-center">
            <span class="text-inProgress">&#9679;</span>
            <span class="font-normal ml-2 block truncate">
              {{ t('order_status.in_progress') }}
            </span>
          </div>
        </li>

        <li class="text-gray-900 cursor-pointer select-none relative py-1 px-1 text-xs hover:bg-gray-100"
            id="listbox-option-2"
            role="option"
            @click="setCurrentStatus('completed')">
          <div class="flex items-center">
            <span class="text-completed">&#9679;</span>
            <span class="font-normal ml-2 block truncate">
              {{ t('order_status.completed') }}
            </span>
          </div>
        </li>

      </ul>

    </div>
  </div>

</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, watch, reactive } from 'vue'

export default ({
  name: 'StatusDropdown',
  props: {
    order: Object,
    updateOrderStatus: Function,
  },
  setup(props) {
    const {t} = useI18n();
    const updateOrderStatus = props.updateOrderStatus;
    const order = reactive(props.order);

    const showDropdown = ref(false);
    const currentStatus = ref(order.status);

    function toggleDropdown(){
      showDropdown.value = !showDropdown.value
    }

    function getLabel(index) {
      const labels = {
        'to_do': 'To do',
        'in_progress': 'In progress',
        'completed': 'Completed',
      }

      return labels[index];
    }

    function getStatusClass(status){
      switch (status) {
        case 'to_do':
          return 'text-toDo mr-2';
        case 'in_progress':
          return 'text-inProgress mr-2';
        case 'completed':
          return 'text-completed mr-2';
      }
    }

    function setCurrentStatus(newStatus){
      currentStatus.value = newStatus;
      showDropdown.value = false;
    }

    watch(currentStatus, (newValue, oldValue) => {
      order.status = currentStatus;
      updateOrderStatus(order);
    })

    return {
      showDropdown,
      toggleDropdown,
      getStatusClass,
      currentStatus,
      setCurrentStatus,
      getLabel,
      t,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>


