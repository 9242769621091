<template>
    <div v-if="isActive" class="loader-container-spinner">
        <div class="sp sp-circle"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoaderSpinner',
  props: {
    isActive: {
      type: String,
      required: true,
    }
  },
  setup(props) {

  },
});

</script>
<style lang="scss">
.loader-container-spinner {
  height: 41px;
  padding: 4px;
  position: absolute;
  left: 30px;
  right: 30px;
  top: calc(50% - 16px);
  z-index: 999;
}

.sp {
  width: 32px;
  height: 32px;
  clear: both;
  margin: auto;
}

.sp-circle {
  border: 4px rgba(#9fce4d, 0.25) solid;
  border-top: 4px rgba(#9fce4d, 1) solid;
  border-radius: 50%;
  animation: spCircRot .6s infinite linear;
}

@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
