
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LoaderSpinner',
  props: {
    isActive: {
      type: String,
      required: true,
    }
  },
  setup(props) {

  },
});

