<template>
  <div v-if="show" class="absolute w-full h-screen bg-black bg-opacity-40 z-30 flex justify-center items-center">
    <div class="relative w-3/4 max-w-3xl h-3/4 max-h-full no-scrollbar overflow-y-auto bg-white rounded-xl p-8">
      <button class="absolute top-3 right-3" @click="onCancel()">
        <svg class="w-4 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
      <div class="font-bold text-base pb-3">
        {{ t('create_order.create_order') }}
      </div>
      <form class="flex flex-col" action="#">
        <div class="my-2">
          <label class="uppercase text-xs font-bold">
            {{ t('create_order.customer_details') }}
          </label>
          <div class="flex w-full justify-between mt-2">
            <div class="flex flex-col w-2/4 pr-2">
              <select id="customerS" name="customer"
                      v-model="selectedCustomer"
                      required
                      class="h-full pl-2 pr-5 border border-gray-200 text-gray-800 sm:text-sm rounded p-1 h-8 mt-2 focus:outline-none focus:ring-2 focus:ring-primary">
                <option
                  :key="customer.userId"
                  :value="customer.userId"
                  v-for="customer in getCustomersList()">
                  {{ customer.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="my-2">
          <label class="uppercase text-xs font-bold">
            {{ t('create_order.order_source') }}
          </label>
          <div class="flex w-full justify-between mt-2">
            <div class="flex flex-col w-2/4 pr-2">
              <select id="orderSource" name="source"
                      v-model="selectedSource"
                      required
                      class="h-full pl-2 pr-5 border border-gray-200 text-gray-800 sm:text-sm rounded p-1 h-8 mt-2 mb-5 focus:outline-none focus:ring-2 focus:ring-primary">
                <option
                  :key="source.value"
                  :value="source.value"
                  v-for="source in getOrderSourceOptions()">
                  {{ source.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <span class="h-px w-full bg-primary my-2"></span>

        <template v-for="(selectedService, index) in getSelectedServices()">
          <div class="my-2">
            <label class="uppercase text-xs font-bold">
              {{ t('create_order.service') }} {{ index + 1 }}
            </label>
            <div class="flex flex-col mt-2">
              <label class="text-xs font-bold">
                {{ t('create_order.choose_service') }}
              </label>

              <div class="flex flex-row mt-2 mb-5">
                <select id="currency1" name="currency1"
                        v-model="selectedServices[index].serviceId"
                        class="h-full pl-2 pr-5 border border-gray-200 text-gray-800 sm:text-sm rounded p-1 h-8 focus:outline-none focus:ring-2 focus:ring-primary">
                  <option
                    :key="service.serviceId"
                    :value="service.serviceId"
                    v-for="service in getServicesList()">
                    {{ service.name }}
                  </option>
                </select>

                <div>
                  <span class="text-xs font-bold ml-10 mr-2">Quantity</span>
                  <input
                    type="number"
                    min="1"
                    class="w-13 h-7 border border-gray-200 text-gray-800 sm:text-sm rounded pl-2 pb-2 pt-2 focus:outline-none focus:ring-2 focus:ring-primary"
                    v-model="selectedServices[index].quantity"/>
                </div>
              </div>

              <label class="text-xs">
                {{ t('create_order.data_privacy_status') }}
              </label>
              <div class="flex justify-start items-center mt-3">
                <input
                  :id="`svc${selectedService.serviceId}`"
                  type="checkbox"
                  class="h-4 w-4"
                  v-model="selectedServices[index].requiresPrivacy">
                <label :for="`svc${selectedService.serviceId}`" class="ml-2 text-xs">
                  {{ t('create_order.requires_privacy') }}
                </label>
              </div>
            </div>
          </div>

          <span class="h-px w-full bg-primary my-3"></span>
        </template>

        <div class="flex justify-center my-3">
          <button
            class="bg-primary text-black w-36 rounded-3xl py-2 text-xs flex justify-center items-center cursor-pointer focus:outline-none font-bold hover:bg-secondary hover:shadow-lg"
            @click="onAddService">
            <svg class="w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
            </svg>
            {{ t('create_order.add_service') }}
          </button>
        </div>
        <div class="flex justify-end my-3">
          <button
            @click.prevent="onCancel()"
            class="bg-white border border-primary w-36 rounded-3xl py-2 text-xs flex justify-center items-center cursor-pointer focus:outline-none text-primary font-bold mr-3 hover:text-white hover:bg-primary">
            {{ t('create_order.cancel') }}
          </button>
          <button
            @click.prevent="onCreateOrder()"
            :disabled="!getSelectedCustomer() || getSelectedServices().length === 0"
            :class="!getSelectedCustomer() || getSelectedServices().length === 0? 'opacity-20 pointer-events-none' : ''"
            class="bg-primary text-black w-36 rounded-3xl py-2 text-xs flex justify-center items-center cursor-pointer focus:outline-none font-bold hover:bg-secondary hover:shadow-lg">
            {{ t('create_order.create_new_order') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {ref, onMounted} from 'vue';
import httpClient from '@/services/httpClient';

export default ({
  name: 'CreateOrder',
  props: {
    show: Boolean,
    toggleShow: Function,
    toggleReloadOrders: Function,
  },

  setup(props) {
    const {t} = useI18n();
    const selectedServices = ref([]);
    const servicesLoaded = ref();
    const customersLoaded = ref();
    const servicesList = ref([]);
    const toggleShow = props.toggleShow;
    const toggleReloadOrders = props.toggleReloadOrders;
    const customersList = ref([]);
    const selectedCustomer = ref();
    const selectedSource = ref();

    const fetchServices = async () => {
      servicesLoaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/services', {});

      servicesList.value = result.data;
      servicesLoaded.value = true;
    }

    const fetchCustomers = async () => {
      customersLoaded.value = false;

      const result = await httpClient.get(
        '/api/v1/dgr/all-customers', {});

      customersList.value = result.data;
      customersLoaded.value = true;
    }

    const postNewOrder = async () => {
      const services = selectedServices.value.filter(e => { return e.serviceId !== 0});

      const result = await httpClient.post(
        '/api/v1/dgr/order_create', {
          customer: selectedCustomer.value,
          orderSource:selectedSource.value,
          services: services,
        });
      console.log("Test", result)
    }

    const onAddService = (e) => {
      e.preventDefault();
      selectedServices.value.push({
        serviceId: 0,
        requiresPrivacy: false,
        quantity: 1,
      });
    }

    onMounted(async () => {
      await fetchServices();
      await fetchCustomers();
    })

    function getServicesList() {
      return servicesList.value;
    }

    function getCustomersList() {
      return customersList.value;
    }

    function getSelectedServices() {
      return selectedServices.value;
    }

    function getSelectedCustomer() {
      return selectedCustomer.value;
    }
    const onCreateOrder = async () => {
      await postNewOrder();
      onCancel();
      toggleReloadOrders();
      // console.warn('create', selectedServices);
    }

    const onCancel = () => {
      toggleShow();
      selectedServices.value = [];
      selectedCustomer.value = null;
    }
    const getOrderSourceOptions = () => {
      return [
        { name: 'HomePage', value: '10055' },
        { name: 'Email', value: '10056' },
        { name: 'Telefon', value: '10057' },
        { name: 'DEVK', value: '10059' },
        { name: 'AXA-Assistance', value: '10060' },
        { name: 'Deutsche-Assistance', value: '10061' },
        { name: 'ARAG', value: '10062' },
        { name: 'Andere', value: '10058' },
      ]
    }

    return {
      t,
      toggleShow,
      getServicesList,
      customersLoaded,
      getCustomersList,
      selectedCustomer,
      servicesLoaded,
      selectedServices,
      getSelectedCustomer,
      getSelectedServices,
      onCancel,
      onAddService,
      onCreateOrder,
      getOrderSourceOptions,
      selectedSource
    };
  },
});

</script>

<style lang="scss">
</style>


