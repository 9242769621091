<template>
<div class="relative mr-3">
  <span class="flex absolute left-2 h-6 w-6 text-gray-800 z-10 mt-2">
      <svg class="w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
      </svg>
  </span>
  <input
    type="text"
    class="relative border rounded-lg py-1 h-10 border-gray-300 w-96 pr-8 px-10 rounded-lg z-0 text-sm outline-none"
    :placeholder="t('filters.search.search_by')"
    v-model="searchValue"
  >
  <button
    class="flex justify-center items-center absolute h-10 w-10 top-0 right-0 bg-primary rounded-r-lg p-4 text-sm text-white z-10 focus:outline-none hover:bg-secondary"
    @click="onSearch()">
    {{ t('filters.search.go') }}
  </button>
</div>
</template>

<script>

import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

export default ({
  name: 'SearchBar',
  props: {
    onFilterOrders: Function,
  },
  setup(props) {
    const {t} = useI18n();
    const searchValue = ref(null);
    const onFilterOrders = props.onFilterOrders;

    function onSearch() {
      onFilterOrders({custom: searchValue.value});
    }

    return {
      t,
      onSearch,
      searchValue,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
