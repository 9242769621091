<template>
  <div class="w-full grid grid-flow-col grid-cols-4 gap-8 mt-4">
    <!-- <div class="w-full overflow-hidden">
      <div class="text-sm">{{ t('filters.filter_by_service.title') }}</div>
      <Multiselect
        class="w-full small-multiselect"
        v-model="selectedServices"
        :options="servicesList"
        optionLabel="name"
        optionValue="id"
        display="chip"
        @hide="onServicesChange"
      />
    </div> -->
    <div class="w-full overflow-hidden">
      <div class="text-sm">{{ t('filters.filter_by_order_status.title') }}</div>
      <Multiselect
        class="w-full"
        v-model="selectedOrderStatuses"
        :options="orderStatusList"
        optionLabel="name"
        optionValue="id"
        display="chip"
        @hide="onOrderStatusChange"
      />
    </div>
    <div class="w-full overflow-hidden">
      <div class="text-sm">{{ t('filters.filter_by_payment_status.title') }}</div>
      <Multiselect
        class="w-full"
        v-model="selectedPaymentStatuses"
        :options="paymentStatusList"
        optionLabel="name"
        optionValue="id"
        display="chip"
        @hide="onPaymentStatusChange"
      />
    </div>
    <div class="w-full overflow-hidden">
      <div class="text-sm">{{ t('filters.filter_by_date.title') }}</div>
      <Button
        class="p-button-outlined p-button-secondary w-full"
        type="button"
        @click="toggleDatePanel"
        aria-controls="overlay_panel"
        iconPos="right"
        icon="pi pi-chevron-down"
        :label="getSelectedCustomDate() ? getSelectedCustomDate() : t('filters.filter_by_date.' + selectedDate)"
      />


      <OverlayPanel
        style="min-width: 250px"
        id="overlay_panel"
        ref="datePanelOpen"
        appendTo="body"
        @close="onDateChange"
        :showCloseIcon="false"
        :dismissable="false" >
        <div>
          <div class="m-4 radio-element" v-for="dateItem in dateList">
            <RadioButton
              :id="`date_${dateItem.id}`"
              name="date"
              :value="dateItem.id"
              class="mr-4"
              v-model="selectedDate" />
            <label
              :for="`date_${dateItem.id}`">
              {{dateItem.name}}
            </label>
          </div>

          <div v-if="selectedDate === 'custom'">
            <Calendar
              class="w-full"
              dateFormat="dd.mm.yy"
              v-model="selectedCustomDate"
              :modelValue="selectedCustomDate"
              :monthNavigator="true"
              :yearNavigator="false"
              :manualInput="false"
              selectionMode="range" />
          </div>

          <Button
            class="p-button-secondary w-full mt-6"
            @click="onDateChange"
            label="OK">
          </Button>
        </div>
      </OverlayPanel>
    </div>
  </div>

</template>

<script>
import httpClient from '@/services/httpClient';
import {onMounted, ref, computed} from 'vue';
import {useI18n} from 'vue-i18n';
import moment from 'moment';
import {useStore} from 'vuex';

export default ({
  name: 'DropdownFilters',
  props: {
    onFilterOrders: Function,
  },
  setup(props) {
    const {t} = useI18n();

    const onFilterOrders = props.onFilterOrders;
    const servicesList = ref([]);
    const selectedServices = ref([]);
    const orderStatusList = ref([]);
    const selectedOrderStatuses = ref([]);
    const paymentStatusList = ref([]);
    const selectedPaymentStatuses = ref([]);
    const dateList = ref([]);
    const selectedDate = ref('all_time');
    const selectedCustomDate = ref();
    const loading = ref(false);
    const datePanelOpen = ref(false);

    const store = useStore();
    const isAdmin = computed(() => store.getters['AuthModule/isAdmin']);
    const isCustomer = computed(() => store.getters['AuthModule/isCustomer']);

    async function fetchAvailableFilters() {
      loading.value = true;
      const result = await httpClient.get('api/v1/dgr/order_filters');
      loading.value = false;

      servicesList.value = result.data.services;
      orderStatusList.value = result.data.order_status.map(e => {
        return {id: e, name: t(`filters.filter_by_order_status.${e}`)}
      });
      paymentStatusList.value = result.data.payment_status.map(e => {
        return {id: e, name: t(`filters.filter_by_payment_status.${e}`)}
      });
      dateList.value = result.data.date.map(e => {
        return {id: e, name: t(`filters.filter_by_date.${e}`)}
      });
    }

    onMounted(async () => {
      await fetchAvailableFilters();
    })

    function onServicesChange() {
      onFilterOrders({filter_services: selectedServices.value});
    }

    function onOrderStatusChange() {
      onFilterOrders({filter_order_status: selectedOrderStatuses.value});
    }

    function onPaymentStatusChange() {
      onFilterOrders({filter_payment_status: selectedPaymentStatuses.value});
    }

    function onDateChange() {
      datePanelOpen.value.hide();
      onFilterOrders({
        filter_date: {
          type: selectedDate.value,
          start: selectedCustomDate.value?.length === 2 && moment(selectedCustomDate.value[0]).format('YYYY-MM-DD'),
          end: selectedCustomDate.value?.length === 2 && moment(selectedCustomDate.value[1]).format('YYYY-MM-DD'),
        }
      });
    }

    function getSelectedCustomDate() {
      if (selectedDate.value === 'custom' && selectedCustomDate.value?.length === 2) {
        if (selectedCustomDate.value[0] && selectedCustomDate.value[1]) {
          return (
            moment(selectedCustomDate.value[0]).format('DD.MM.YYYY')
            + ' - ' +
            moment(selectedCustomDate.value[1]).format('DD.MM.YYYY')
          );
        }
      }
      return false;
    }

    function toggleDatePanel(e) {
      datePanelOpen.value.toggle(e);
    }

    return {
      servicesList,
      paymentStatusList,
      orderStatusList,
      dateList,
      t,
      selectedServices,
      selectedOrderStatuses,
      selectedPaymentStatuses,
      selectedDate,
      onServicesChange,
      onOrderStatusChange,
      onPaymentStatusChange,
      onDateChange,
      datePanelOpen,
      toggleDatePanel,
      selectedCustomDate,
      getSelectedCustomDate,
      isAdmin,
      isCustomer,
    };
  },
});

</script>

<style lang="css">

    .p-multiselect, .p-component, .p-dropdown-label {
      font-size: 12px;
    }

    .p-component.p-button.p-button-outlined.p-button-secondary {
      border-color: #ced4da;
    }

    .small-multiselect .p-multiselect-token {
      max-width: 100px;
      overflow: hidden;
    }

    .small-multiselect .p-multiselect-token-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .p-overlaypanel .p-overlaypanel-close {
      background: rgba(159, 206, 77, .9);
    }

    .p-overlaypanel .p-overlaypanel-close.p-link:hover {
      background: rgba(159, 206, 77, 1);
    }

</style>
